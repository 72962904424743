<template>
    <div id="china_map_box">   
        <div id="china_map" ref="mapDiv" style="width: 1900px; height:800px"></div>
    </div>
  </template>
  
  <script>
  import echarts from "echarts";
  import 'echarts/map/js/china.js' 
  export default {
    data() {
      return {
        //echart 配制option  
        options: {
          tooltip: {
            triggerOn: "mousemove",   //mousemove、click
            padding:8,
            borderWidth:1,
            borderColor:'#409eff',
            backgroundColor:'rgba(255,255,255,0.7)',
            textStyle:{
              color:'#000000',
              fontSize:13
            },
            formatter: function(e, t, n) {
              let data = e.data;
              //模拟数据
              data.specialImportant = Math.random()*1000 | 0;
              // data.import = Math.random()*100 | 0;
              data.compare = Math.random()*1000 | 0;
              data.common = Math.random()*1000 | 0;
              data.specail = Math.random()*1000 | 0;
  
              let context = `
                 <div class="content">
                     <p><b style="font-size:15px;">省份:${data.name}</p>
                     <p class="tooltip_style"><span class="tooltip_left">潜在买家指数:</span><span class="tooltip_right">${data.value}</span></p>
                     <p class="tooltip_style"><span class="tooltip_left">质量指数:</span><span class="tooltip_right">${data.specialImportant}</span></p>
                     <p class="tooltip_style"><span class="tooltip_left">占比:</span><span class="tooltip_right">${data.import}%</span></p>
                 </div>
              `
              return context;
            }
          },
          visualMap: {
            show:true,
            left: 26,
            bottom: 40,
            showLabel:true,
            pieces: [
              {
                gte: 100,
                label: ">= 200",
                color: "#0955CE"
              },
              {
                gte: 10,
                lt: 199,
                label: "150 - 199",
                color: "#2980FF"
              },
              {
                gte: 10,
                lt:149,
                label: "100 - 149",
                color: "#6B9EFF"
              },
              {
                gte: 10,
                lt: 99,
                label: "50 - 99",
                color: "#9CC2FF"
              },
              {
                lt:50,
                label:'<50',
                color: "#E6F0FF"
              }
            ]
          },
          geo: {
            map: "china",
            scaleLimit: {
              min: 1,
              max: 2
            },
            zoom: 1,
            top: 120,
            label: {
              normal: {
                show:false,
                fontSize: "14",
                color: "rgba(0,0,0,0.7)"
              }
            },
            itemStyle: {
              normal: {
                //shadowBlur: 50,
                //shadowColor: 'rgba(0, 0, 0, 0.2)',
                borderColor: "rgba(0, 0, 0, 0.2)"
              },
              emphasis: {
                areaColor: "#0955ce",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                borderWidth: 0
              }
            }
          },
          series: [
            {
              name: "突发事件",
              type: "map",
              geoIndex: 0,
              data:[]
            }
          ]
        },
        //echart data
        dataList: [
          {
            name: "南海诸岛",
            value: 126,
            eventTotal:100,
            specialImportant:1252,
            import:3.0,
          },
          {
            name: "北京",
            value: 248,
            eventTotal:100,
            specialImportant:1252,
            import:5.8,
          },
          {
            name: "天津",
            value: 186,
            eventTotal:100,
            specialImportant:1252,
             import:4.4,
          },
          {
            name: "上海",
            value: 165,
            eventTotal:100,
            specialImportant:1252,
             import:3.9,
          },
          {
            name: "重庆",
            value: 49,
            eventTotal:100,
            specialImportant:1252,
             import:1.1,
          },
          {
            name: "河北",
            value: 148,
            eventTotal:100,
            specialImportant:1252,
            import:3.5,
          },
          {
            name: "河南",
            value: 25,
            eventTotal:100,
            specialImportant:1252,
            import:0.6,
          },
          {
            name: "云南",
            value: 40,
            eventTotal:100,
            specialImportant:1252,
            import:0.9,
          },
          {
            name: "辽宁",
            value: 107,
            eventTotal:100,
            specialImportant:1252,
            import:2.5,
          },
          {
            name: "黑龙江",
            value: 76,
            eventTotal:100,
            specialImportant:1252,
            import:1.8,
          },
          {
            name: "湖南",
            value: 19,
            eventTotal:100,
            specialImportant:1252,
            import:0.4,
          },
          {
            name: "安徽",
            value: 119,
            eventTotal:100,
            specialImportant:1252,
            import:2.8,
          },
          {
            name: "山东",
            value: 152,
            eventTotal:100,
            specialImportant:1252,
            import:3.6,
          },
          {
            name: "新疆",
            value: 72,
            eventTotal:100,
            specialImportant:1252,
            import:1.7,
          },
          {
            name: "江苏",
            value: 135,
            eventTotal:100,
            specialImportant:1252,
            import:3.1,
          },
          {
            name: "浙江",
            value: 127,
            eventTotal:100,
            specialImportant:1252,
            import:3.0,
          },
          {
            name: "江西",
            value: 8,
            eventTotal:100,
            specialImportant:1252,
            import:0.2,
          },
          {
            name: "湖北",
            value: 55,
            eventTotal:100,
            specialImportant:1252,
            import:1.3,
          },
          {
            name: "广西",
            value: 154,
            eventTotal:100,
            specialImportant:1252,
            import:3.6,
          },
          {
            name: "甘肃",
            value: 64,
            eventTotal:100,
            specialImportant:1252,
            import:1.5,
          },
          {
            name: "山西",
            value: 201,
            eventTotal:100,
            specialImportant:1252,
            import:4.7,
          },
          {
            name: "内蒙古",
            value: 233,
            eventTotal:100,
            specialImportant:1252,
            import:5.5,
          },
          {
            name: "陕西",
            value: 83,
            eventTotal:100,
            specialImportant:1252,
            import:2.0,
          },
          {
            name: "吉林",
            value: 110,
            eventTotal:100,
            specialImportant:1252,
            import:2.6,
          },
          {
            name: "福建",
            value: 176,
            eventTotal:100,
            specialImportant:1252,
            import:4.1,
          },
          {
            name: "贵州",
            value: 27,
            eventTotal:100,
            specialImportant:1252,
            import:0.6,
          },
          {
            name: "广东",
            value: 143,
            eventTotal:100,
            specialImportant:1252,
            import:3.4,
          },
          {
            name: "青海",
            value: 94,
            eventTotal:100,
            specialImportant:1252,
            import:2.2,
          },
          {
            name: "西藏",
            value: 51,
            eventTotal:100,
            specialImportant:1252,
            import:1.2,
          },
          {
            name: "四川",
            value: 32,
            eventTotal:100,
            specialImportant:1252,
            import:0.8,
          },
          {
            name: "宁夏",
            value: 96,
            eventTotal:100,
            specialImportant:1252,
            import:2.3,
          },
          {
            name: "海南",
            value: 158,
            eventTotal:100,
            specialImportant:1252,
            import:3.7,
          },
          {
            name: "台湾",
            value: 123,
            eventTotal:100,
            specialImportant:1252,
            import:2.9,
          },
          {
            name: "香港",
            value: 6.1,
            eventTotal:100,
            specialImportant:1252,
            import:6.8,
          },
          {
            name: "澳门",
            value: 389,
            eventTotal:100,
            specialImportant:1252,
            import:9.2,
          }
        ]
      };
    },
    methods: {
      //初始化中国地图
      initEchartMap() {
        let mapDiv = document.getElementById('china_map');
        let myChart = echarts.init(this.$refs.mapDiv);
        myChart.setOption(this.options);
      },
      //修改echart配制
      setEchartOption(){
         this.options.series[0]['data'] = this.dataList;
      }
    },
    created() {
      this.setEchartOption();
    },
    mounted() {
        this.$nextTick(()=>{
            this.initEchartMap();
        })
      
    },
  
  };
  </script>
  
  <style scoped>
      #china_map_box {
          height: 100%;
          position: relative;
      }
      #china_map_box #china_map{
          height: 100%;
      }
       #china_map_box .china_map_logo{
          position: absolute;
          top: 0;
          left: 0;
          width:45px;
       }
  </style>
  <style>
    #china_map .tooltip_style{
   
        overflow: hidden;
       
    }
    #china_map .tooltip_left{
        float: left;
        
    }
    #china_map .tooltip_right{
        float: right;
        margin-left: 5px;
    }
    #china_map .content{
        /* width: 200px; */
        padding: 0 10px;
       
        border: none;
     
    }
  
  </style>
  